// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use "@angular/material" as mat;

// Plus imports for other components in your app.

@include mat.all-component-typographies;
@include mat.core;

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$artemis-portal-primary: mat.define-palette(mat.$teal-palette);
$artemis-portal-accent: mat.define-palette(mat.$red-palette, 200, 100, 400);

// The warn palette is optional (defaults to red).
$artemis-portal-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$artemis-portal-theme: mat.define-light-theme(
  (
    color: (
      primary: $artemis-portal-primary,
      accent: $artemis-portal-accent,
      warn: $artemis-portal-warn,
    ),
  )
);

@include mat.all-component-themes($artemis-portal-theme);

/* You can add global styles to this file, and also import other style files */

// Very very important for layout, DO NOT REMOVE!
html,
body {
  width: 100%;
  height: 100%;
  min-width: 500px;
}

// use flex box for layout
body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif !important;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

app-root {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-items: stretch;
}

.material-symbols-outlined {
  font-variation-settings: "FILL" 0, "wght" 400, "GRAD" 0, "opsz" 48;
}

.cdk-overlay-pane .mat-mdc-menu-panel {
  .mat-mdc-menu-item .mdc-list-item__primary-text {
    opacity: 0.8;
    font-size: 14px;
  }

  background: white;
}

.mdc-tooltip__surface {
  background: rgb(97 97 97 / 90%) !important;
  color: white !important;
}

.mat-mdc-button {
  padding: 0 16px !important;
}

.mat-mdc-raised-button:not(:disabled) {
  background-color: #ef9a9a !important;
}

.mat-mdc-dialog-container .mdc-dialog__title {
  font-family: Roboto, "Helvetica Neue", sans-serif !important;
  font-size: 24px !important;
  letter-spacing: normal !important;
  margin: 0 0 16px;
}

.mat-mdc-dialog-container .mdc-dialog__content {
  font-family: Roboto, "Helvetica Neue", sans-serif !important;
  font-weight: 300 !important;
  letter-spacing: normal !important;

  p {
    line-height: 20px;
  }
}

.mat-mdc-text-field-wrapper {
  background-color: rgb(0 0 0 / 4%);
}

.mdc-snackbar__surface {
  background: #323232 !important;
  color: white !important;
}

.mat-mdc-select-panel-above .mdc-menu-surface.mat-mdc-select-panel {
  background-color: white;
  box-shadow:
    0 2px 4px -1px rgb(0 0 0 / 20%),
    0 4px 5px 0 rgb(0 0 0 / 14%),
    0 1px 10px 0 rgb(0 0 0 / 12%);
}

.mat-mdc-option-active span {
  color: #009688;
}

.mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.mat-primary .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  display: none;
}

.mdc-button.mat-warn:not(.mat-mdc-button:disabled) {
  color: #f44336 !important;
}

.mdc-button__label {
  font-family: Roboto, "Helvetica Neue", sans-serif !important;
  font-size: 14px;
  letter-spacing: 0;
  font-weight: 500 !important;
}

.mat-mdc-form-field-infix {
  input {
    padding-bottom: 10px;
  }

  padding-top: 25px;
}

.mdc-text-field--invalid {
  color: #f44336 !important;
}

.mat-mdc-form-field-error {
  color: #f44336;
  font-size: 75%;
}

.mdc-text-field--focused {
  color: #009688;
}

.mat-mdc-card.mat-elevation-z6 {
  @include mat.elevation(6);
}

.mat-mdc-card.mat-elevation-z8 {
  @include mat.elevation(8);
}

.mat-mdc-card.mat-elevation-z10 {
  @include mat.elevation(10);
}
